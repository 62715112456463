.banner-slider {
  overflow: hidden;
  position: relative; }
  @media (max-width: 767px) {
    .banner-slider {
      height: auto; } }
  .banner-slider__arrow {
    background-size: 30px;
    border: none;
    color: transparent;
    cursor: pointer;
    height: 100%;
    outline: none;
    position: absolute;
    top: 0;
    transition: transform .2s linear;
    width: 100px;
    z-index: 1; }
    .banner-slider__arrow:before {
      background: rgba(255, 255, 255, 0);
      background: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
      bottom: 0;
      content: '';
      left: 0;
      opacity: .6;
      position: absolute;
      right: 0;
      top: 0; }
    .banner-slider__arrow--next {
      background: url(../../../images/icon-gal-right-white.svg) center no-repeat;
      right: 0; }
      .banner-slider__arrow--next:before {
        background: linear-gradient(to right, rgba(28, 20, 23, 0) 0%, rgba(28, 20, 23, 0.7) 100%); }
      .banner-slider__arrow--next:hover {
        transform: translate3d(4px, 0, 0); }
    .banner-slider__arrow--prev {
      background: url(../../../images/icon-gal-left-white.svg) center no-repeat;
      left: 0; }
      .banner-slider__arrow--prev:before {
        background: linear-gradient(to right, rgba(28, 20, 23, 0.7) 0%, rgba(28, 20, 23, 0) 100%); }
      .banner-slider__arrow--prev:hover {
        transform: translate3d(-4px, 0, 0); }
    @media (max-width: 767px) {
      .banner-slider__arrow {
        background-size: 20px;
        width: 70px; } }
  .banner-slider__background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    .banner-slider__background:before {
      background: rgba(28, 20, 23, 0.3);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .banner-slider__bar {
    background: #FFFFFF;
    position: relative;
    z-index: 1; }
  .banner-slider__box {
    display: block;
    width: 100%;
    text-align: left; }
  .banner-slider__box-content {
    outline: none;
    text-decoration: none; }
  .banner-slider__image, .banner-slider__video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }
  .banner-slider__link {
    color: #FFFFFF;
    display: block;
    font-size: 27px;
    font-weight: 300;
    line-height: 35px;
    margin-top: 38px;
    text-decoration: none; }
    @media (max-width: 991px) {
      .banner-slider__link {
        margin-top: 50px; } }
    @media (max-width: 767px) {
      .banner-slider__link {
        font-size: 20px;
        line-height: 28px;
        margin-top: 24px; } }
    .banner-slider__link--uppercase {
      text-transform: uppercase; }
  .banner-slider__slide {
    align-items: end;
    display: flex;
    height: 100%;
    padding: 80px 0;
    position: relative; }
    @media (max-width: 767px) {
      .banner-slider__slide {
        padding: 64px 0 32px; } }
    @media (max-width: 575px) {
      .banner-slider__slide {
        padding-bottom: 20px; } }
  .banner-slider__subtitle {
    color: #FFFFFF;
    font-size: 27px;
    font-weight: 500;
    line-height: 32px;
    margin-top: 20px;
    max-width: 90%; }
    @media (max-width: 767px) {
      .banner-slider__subtitle {
        font-size: 18px;
        letter-spacing: -0.59px;
        line-height: 26px;
        margin-top: 10px; } }
  .banner-slider__title p {
    color: #FFFFFF;
    font-size: 40px;
    font-weight: 700;
    line-height: 54px; }
    @media (max-width: 767px) {
      .banner-slider__title p {
        font-size: 24px;
        letter-spacing: -0.66px;
        line-height: 32px; } }
  .banner-slider__wrapper {
    height: 66vw;
    max-height: 500px;
    overflow: hidden; }
    @media (max-width: 767px) {
      .banner-slider__wrapper {
        height: 92vh;
        max-height: 350px; } }
  .banner-slider .slick-list {
    height: 100%;
    margin: 0; }
  .banner-slider .slick-track {
    height: 100%; }
  .banner-slider .slick-slide {
    align-items: end;
    display: flex; }
